import {ThunkAction} from "redux-thunk";
import {AppStateType} from "../reducers";
import {
    DELETE_BOX_LINK_ERROR,
    DELETE_BOX_LINK_REQUEST, DELETE_BOX_LINK_SUCCESS,
    GET_LINK_ERROR,
    GET_LINK_REQUEST,
    GET_LINK_SUCCESS,
    NEW_LINK_BOX_ERROR,
    NEW_LINK_BOX_REQUEST,
    NEW_LINK_BOX_SUCCESS,
    NEWLY_CREATED_BOX_LINK,
    SET_LINK_ERRORS,
    UNLOCK_BOX_LINK_ERROR,
    UNLOCK_BOX_LINK_REQUEST,
    UNLOCK_BOX_LINK_SUCCESS, UPDATED_BOX_LINK_ERROR,
    UPDATED_BOX_LINK_REQUEST, UPDATED_BOX_LINK_SUCCESS
} from "../constants/actionTypes";

import {} from "react-router-dom";


import * as LinkApi from "../api/links";


export type LinksActionTypes =
    NewLinkBoxRequest
    | NewLinkBoxError
    | NewLinkBoxRequestSuccess
    | GetLinkRequestType
    | GetLinkRequestSuccessType
    | GetLinkRequestErrorType
    | CreatedBoxLinkType
    | SetLinkErrorsType
    | UnlockBoxLinkRequestType
    | UnlockBoxLinkErrorType
    | UnlockBoxLinkSuccessType
    | UpdateBoxLinkRequestType
    | UpdateBoxLinkSuccessType
    | UpdateBoxLinkErrorType
    | DeleteBoxLinkRequestType
    | DeleteBoxLinkRequestSuccessType
    | DeleteBoxLinkRequestErrorType;

type NewLinkBoxRequest = {
    type: typeof NEW_LINK_BOX_REQUEST;
}
const newLinkBoxRequest = ():NewLinkBoxRequest => {
    return {
        type: NEW_LINK_BOX_REQUEST
    }
}
type NewLinkBoxError = {
    type: typeof NEW_LINK_BOX_ERROR;
    errors: any;
}
const newLinkBoxError = (e: any): NewLinkBoxError => {
    return {
        type: NEW_LINK_BOX_ERROR,
        errors: e
    }
}
type NewLinkBoxRequestSuccess = {
    type: typeof NEW_LINK_BOX_SUCCESS;
    result: any;
}

const newLinkBoxRequestSuccess = (response: any): NewLinkBoxRequestSuccess => {
    return {
        type: NEW_LINK_BOX_SUCCESS,
        result: response
    }
}

type CreatedBoxLinkType = {
    type: typeof NEWLY_CREATED_BOX_LINK,
    createdBoxLink: object
}
const newlyCreatedBoxLink = (boxLink: any): CreatedBoxLinkType => {
    return {
        type: NEWLY_CREATED_BOX_LINK,
        createdBoxLink: boxLink
    }
}

export const createNewLinkBox = (links: any):
    ThunkAction<Promise<void>, AppStateType, undefined, LinksActionTypes > => async (dispatch,getState) => {
    dispatch(newLinkBoxRequest());
    try {
        const response: any = await LinkApi.createLinkBox(links);
        dispatch(newlyCreatedBoxLink(response));
        dispatch(getLinkRequestSuccess(response));
        //await dispatch(getLink(response.id, response.access_token));
    } catch (e: any) {
        const errors = getState().links.errors;
        const newErrorKey = Object.keys(errors).length + 1;
        let newErrors = {
            ...errors,
            [newErrorKey]: e.response.data.detail[0].msg
        }
        dispatch(newLinkBoxError(newErrors));
    }

}

type UpdateBoxLinkRequestType = {
    type: typeof UPDATED_BOX_LINK_REQUEST;
}
const updateBoxLinkRequest = (): UpdateBoxLinkRequestType => {
    return {
        type: UPDATED_BOX_LINK_REQUEST
    }
}
type UpdateBoxLinkSuccessType = {
    type: typeof UPDATED_BOX_LINK_SUCCESS,
    result: any
}
const updatedBoxLinkSuccess = (result: any): UpdateBoxLinkSuccessType => {
    return {
        type: UPDATED_BOX_LINK_SUCCESS,
        result: result
    }
}
type UpdateBoxLinkErrorType = {
    type: typeof UPDATED_BOX_LINK_ERROR,
    error: any
}
const updateBoxLinkError = (error: any): UpdateBoxLinkErrorType => {
    return {
        type: UPDATED_BOX_LINK_ERROR,
        error: error
    }
}
export const updateExistingLink = (payload: any, link: string):
    ThunkAction<Promise<void>, AppStateType, undefined, LinksActionTypes > => async (dispatch,getState) => {
    dispatch(updateBoxLinkRequest());
    try {
        const response: any = await LinkApi.updateBoxLink(payload, link);
        dispatch(updatedBoxLinkSuccess(response));
    } catch (e: any) {
        const errors = getState().links.errors;
        const newErrorKey = Object.keys(errors).length + 1;
        let newErrors = {
            ...errors,
            [newErrorKey]: e.response.data.detail[0].msg
        }
        dispatch(updateBoxLinkError(newErrors));
    }
}

type DeleteBoxLinkRequestType = {
    type: typeof DELETE_BOX_LINK_REQUEST;
}
const deleteBoxLinkRequest = (): DeleteBoxLinkRequestType => {
    return {
        type: DELETE_BOX_LINK_REQUEST
    }
}
type DeleteBoxLinkRequestSuccessType = {
    type: typeof DELETE_BOX_LINK_SUCCESS;
}
const deleteBoxLinkRequestSuccess = (): DeleteBoxLinkRequestSuccessType => {
    return {
        type: DELETE_BOX_LINK_SUCCESS
    }
}
type DeleteBoxLinkRequestErrorType = {
    type: typeof DELETE_BOX_LINK_ERROR;
    error: any;
}
const deleteBoxLinkRequestError = (error: any): DeleteBoxLinkRequestErrorType => {
    return {
        type: DELETE_BOX_LINK_ERROR,
        error: error
    }
}

export const deleteBoxLink = (linkId: string):
    ThunkAction<Promise<void>, AppStateType, undefined, LinksActionTypes > => async (dispatch,getState) => {
    dispatch(deleteBoxLinkRequest());
    try {
        await LinkApi.deleteBoxLink(linkId);
        dispatch(deleteBoxLinkRequestSuccess());
        window.location.href = '/my-links';
    } catch (e: any) {
        const errors = getState().links.errors;
        const newErrorKey = Object.keys(errors).length + 1;
        let newErrors = {
            ...errors,
            [newErrorKey]: e.response.data.detail[0].msg
        }
        dispatch(deleteBoxLinkRequestError(newErrors));
    }
}

type GetLinkRequestType = {
    type: typeof GET_LINK_REQUEST;
}
type GetLinkRequestSuccessType = {
    type: typeof GET_LINK_SUCCESS;
    result: any;
}
type GetLinkRequestErrorType = {
    type: typeof GET_LINK_ERROR;
    errors: any;
}
const getLinkRequest = (): GetLinkRequestType => {
    return{
        type: GET_LINK_REQUEST
    }
}
const getLinkRequestSuccess = (result: any): GetLinkRequestSuccessType => {
    return {
        type: GET_LINK_SUCCESS,
        result: result
    }
}
const getLinkRequestError = (errors: any): GetLinkRequestErrorType => {
    return {
        type : GET_LINK_ERROR,
        errors: errors
    }
}
type  SetLinkErrorsType = {
    type: typeof SET_LINK_ERRORS;
    errors: any;
}
const setLinkErrors = (errors: any): SetLinkErrorsType => {
    return  {
        type: SET_LINK_ERRORS,
        errors: errors
    }
}
export const getLink = (linkId: string, token: string | null = null): ThunkAction<Promise<void>, AppStateType, undefined, LinksActionTypes > => async (dispatch,getState) => {
    dispatch(getLinkRequest());
    try {
        const response = await LinkApi.getLink(linkId, token);
        dispatch(getLinkRequestSuccess(response));
    }catch (e: any) {
        const errors = getState().links.errors;
        // const newErrorKey = Object.keys(errors).length + 1;
        const newErrors = {"message": e.response.data, "code": e.response.status}
        dispatch(getLinkRequestError(newErrors))
        // navigate(`/unlock-link/${linkId}`);
    }
}

export const goto404 = ():ThunkAction<Promise<void>, AppStateType, undefined, LinksActionTypes > => async (dispatch)  => {
    return
}

export const removeError = (errorIndex: number):ThunkAction<Promise<void>, AppStateType, undefined, LinksActionTypes > => async (dispatch, getState) => {
    let errors = getState().links.errors;
    delete errors[errorIndex];
    await setLinkErrors(errors);
}
type UnlockBoxLinkRequestType = {
    type: typeof UNLOCK_BOX_LINK_REQUEST
}
type UnlockBoxLinkErrorType = {
    type: typeof UNLOCK_BOX_LINK_ERROR,
    error: string
}
type UnlockBoxLinkSuccessType = {
    type: typeof UNLOCK_BOX_LINK_SUCCESS,
    result: any
}
const unlockBoxLinkRequest = ():UnlockBoxLinkRequestType => {
    return {
        type: UNLOCK_BOX_LINK_REQUEST
    }
}
const  unlockBoxLinkError = (e: string): UnlockBoxLinkErrorType => {
    return {
        type: UNLOCK_BOX_LINK_ERROR,
        error: e
    }
}

const unlockBoxLinkSuccess = (result: any): UnlockBoxLinkSuccessType => {
    return {
        type: UNLOCK_BOX_LINK_SUCCESS,
        result: result
    }
}

export const unlockBoxLink = (payload: any, linkBoxId: string): ThunkAction<Promise<void>, AppStateType, undefined, LinksActionTypes> => async (dispatch, getState) => {
    dispatch(unlockBoxLinkRequest());
    try {
        const response = await LinkApi.unlockBoxLink(payload);
        dispatch(unlockBoxLinkSuccess(response));
        await dispatch(getLink(response.linkId, response.accessToken));
        // await navigate(`/l/${response.linkId}`);
    }catch (e: any) {
        const errors = getState().links.errors;
        const newErrorKey = Object.keys(errors).length + 1;
        let newErrors = {
            ...errors,
            [newErrorKey]: e.response.data.detail
        }
        dispatch(newLinkBoxError(newErrors));
        // dispatch(unlockBoxLinkError(e));
    }
}

export const sendLinkEvent = (link: string, eventType: string): ThunkAction<Promise<void>, AppStateType, undefined, LinksActionTypes> => async () => {
    await LinkApi.sharedLinkEvent(link, eventType);
}
