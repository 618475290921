import React, {FC} from "react";

import {IReactTinyLinkProps} from "./ReactTinyLinkTypes";
import {defaultData, getHostname, noop} from "./utils";
import {useScraper} from "./useScraper";
import { Card, ContentWrapper, Header, Content, Footer } from './components/Card'
import CardMedia from './components/CardMedia'
import Description from './components/Description'
import {endpoint} from "../../constants/constants";


const ReactTinyLink: FC<IReactTinyLinkProps> = ({
                                                    cardSize = "large",
                                                    maxLine = 2,
                                                    minLine = 1,
                                                    header = null,
                                                    description = null,
                                                    url = '',
                                                    width = '320px',
                                                    noCache = false,
                                                    proxyUrl = `${endpoint}/api/proxy`,
                                                    showGraphic = true,
                                                    autoPlay = false,
                                                    defaultMedia = '',
                                                    loadSecureUrl = false,
                                                    onError = noop, // Permit to keep a constant reference
                                                    onSuccess = noop,
                                                    onClick = (e, d) => {},
                                                    requestHeaders = { 'x-requested-with': '' },
                                                }: IReactTinyLinkProps) => {

    const defaultMedias = defaultMedia ? [defaultMedia] : []
    const [data, loading] = useScraper({
        url,
        proxyUrl,
        requestHeaders,
        defaultMedias,
        defaultValue: defaultData(url, defaultMedias),
        noCache,
        onError,
        onSuccess,
    })

    return (
        <>
            {
                <Card
                    onClick={e => {
                        if (!onClick) return
                        e.preventDefault()
                        onClick(e, data)
                    }}
                    className="react_tinylink_card mt-2"
                    cardSize={cardSize}
                    href={url}
                    // width={width}
                    isShownGraphic={showGraphic}
                >
                    {showGraphic && (
                        <CardMedia autoPlay={autoPlay} cardSize={cardSize} data={data} loadSecureUrl={loadSecureUrl} />
                    )}
                    <ContentWrapper className="react_tinylink_card_content_wrapper" cardSize={cardSize}>
                        <Header maxLine={maxLine} minLine={minLine} className="react_tinylink_card_content_header">
                            <Description
                                loading={loading}
                                loadingWidth1={2}
                                maxLine={maxLine}
                                minLine={minLine}
                                className="react_tinylink_card_content_header_description"
                            >
                                {header ? header : data !== undefined && (!(data) || data.title) ? "title" in data ? data.title : url : url}
                            </Description>
                        </Header>
                        <Content maxLine={maxLine} minLine={minLine} className="react_tinylink_card_content" cardSize={cardSize}>
                            <Description loading={loading} loadingWidth1={1} className="react_tinylink_card_content_description">
                                {description ? description : data !== undefined && (!(data) || data.description) ? "description" in data ? data.description : url : url}
                            </Description>
                        </Content>
                        <Footer className="react_tinylink_footer">
                            <Description loading={loading} loadingWidth={1} className="react_tinylink_card_footer_description">
                                {getHostname(url)}
                            </Description>
                        </Footer>
                    </ContentWrapper>
                </Card>
            }
        </>
    )
}
export default ReactTinyLink;
