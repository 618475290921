import {Form} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {unlockBoxLink} from "../../actions/linksActions";
import {useDispatch, useSelector} from "react-redux";

import {AppStateType} from "../../reducers";
import ErrorPopover from "../errorPopover";
import {useNavigate} from "react-router-dom";

const LinkUnlockForm = (props: any) => {
    const {isFetching, currentLink, errors} = useSelector((state: AppStateType) => state.links);

    const [password, setPassword] = useState("");
    const [validated, setValidated] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() =>{
        if (!isFetching && currentLink.id !== undefined ) {
            navigate(`/l/${currentLink.shortLink}`);
        }
    }, [isFetching, currentLink, navigate])

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            setValidated(true)
            return
        }
        setValidated(true)
        const payload = {
            password: password,
            linkId: props.linkBoxId,
        }
        dispatch(unlockBoxLink(payload, props.linkBoxId));
    }

    return (
        <div>
            <Form onSubmit={handleSubmit} noValidate validated={validated}>
                <Form.Group className={"m-3"}>
                    <Form.Control id="password"
                                  name="password"
                                  type="password"
                                  required
                                  placeholder="Your link password"
                                  value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                    />
                </Form.Group>
            </Form>
            <div className={"d-flex flex-column error-container"}>

                {/*{errors ? Object.keys(errors).map((key: any) => {*/}
                {/*        return <ErrorPopover error={errors[key]} index={key}/>}*/}
                {/*    )*/}
                {/*    : null}*/}
            </div>
            </div>
    )
}
export default LinkUnlockForm;