import {self_host} from "../../../constants/constants";
import {FaTelegram, IoLogoWhatsapp, MdEmail} from "react-icons/all";
import React, {FC} from "react";
import addIcon from "../../../resources/img/boxlink-add.svg";
import {sendLinkEvent} from "../../../actions/linksActions";
import {useDispatch} from "react-redux";
import "./share.scss";


interface ShareBlockInterface {
    shortLink: string , description: string
}

const ShareBlock: FC<ShareBlockInterface> = ({shortLink, description}) => {
    const dispatch = useDispatch();

    const handleShareEvent = (e: any, value: string, event_type: string) => {
        dispatch(sendLinkEvent(value, event_type))
    }
    return (
        <div className={"mb-4 d-flex top-links-row"}>
            <div className={"font-color-blue"}>
                Share
                <a
                    href={`mailto:?Subject=Boxlink shared with you&body=Hi! Check out this Boxlink ${self_host}/l/${shortLink} about ${description}`}
                    className={"share-icon"}
                    onClick={(e) => handleShareEvent(e, shortLink, 'email-share')}>
                    <MdEmail/>
                </a>
                <a
                    href={`https://t.me/share/url?url=${self_host}/l/${shortLink}&text=Hi! Check out this Boxlink about ${description}`}
                    className={"share-icon"}
                    target={"_blank"}
                    onClick={(e) => handleShareEvent(e, shortLink, 't-gram-share')}>
                    <FaTelegram/>
                </a>
                <a
                    href={`https://wa.me/?text='Hi! Check out this Boxlink ${self_host}/l/${shortLink} about ${description}`}
                    className={"share-icon"}
                    target={"_blank"}
                    onClick={(e) => handleShareEvent(e, shortLink, 'whatsapp-share')}>
                    <IoLogoWhatsapp/>
                </a>
            </div>
            <div>
                <a href={self_host}  target={"_blank"} className={"font-color-blue create-new-button"}>
                    <span>Create new</span><img src={addIcon} className={"add-icon"}/>
                </a>
            </div>
        </div>
    )
}
export default ShareBlock;