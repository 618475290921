import Container from "react-bootstrap/Container";

const FooterBar = () => {
    const color = {color: "black"};
    return (
        <section className={"w-100 text-center pt-4 pb-4"}>
            <Container className={"px-4 d-flex flex-row justify-content-center"}>
                {/*<div className={"m-2"}><a href={"#"}>Facebook</a></div>*/}
                <div className={"m-2"}><a style={color} href={"/cookies-policy"}>Cookies polciy</a></div>
                <div className={"m-2"}>|</div>
                <div className={"m-2"}><a
                    style={color}
                    href={"mailto:boxlinkone@gmail.com"}>Email Us</a></div>
                <div className={"m-2"}>|</div>
                <div className={"m-2"}>2022</div>
            </Container>
        </section>
    )
}
export default FooterBar;