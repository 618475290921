import {combineReducers} from "redux";
import {links} from "./linksReducer";
import {linksListing} from "./linksListingReducer";
import {auth} from "./authReducer";

const mainReducer = combineReducers({
    // global,
    links,
    linksListing,
    auth,
})

type MainReducerType = typeof mainReducer;
export type AppStateType = ReturnType<MainReducerType>;

export default mainReducer;