import {PAGINATION_SIZE} from "../../constants/constants";
import SmallBoxLinkCard from "../smallBoxLinkCard";
import LinkForm from "../linkForm";
import React from "react";
import {useDispatch} from "react-redux";
import "./linksListing.scss";

const LinksListing = (props: any) => {

    const dispatch = useDispatch();
    const moveByPage = (offset: number, limit: number) => {
        if (offset > props.links.total){
            offset = props.links.total
        }
        if (offset === props.links.total){
            offset = offset - limit
        }
        dispatch(props.listingCallback(offset, limit));
    }

    return (
        <>
            {props.links.results.length > 0 ?
                    <>
                        <div className={"w-100 d-flex justify-content-between paginator "}>
                            <div>
                                {
                                    props.links.offset > 0 ?
                                        <a
                                            onClick={() => {moveByPage(0, PAGINATION_SIZE)}}
                                        >First page </a>
                                        : null
                                }
                                {
                                    (parseInt(props.links.offset) - PAGINATION_SIZE >= 0) ?
                                        <>
                                            <span>|</span>
                                            <a
                                                onClick={() => {moveByPage(
                                                    props.links.offset - PAGINATION_SIZE, PAGINATION_SIZE
                                                )}}
                                            >Back</a>
                                        </>
                                        : null
                                }
                            </div>
                            <div>
                                {
                                    (parseInt(props.links.offset) + PAGINATION_SIZE < props.links.total) ?
                                        <>
                                            <a
                                                onClick={() => {moveByPage(
                                                    props.links.offset + PAGINATION_SIZE, PAGINATION_SIZE
                                                )}}
                                            >Next</a>
                                            <span>|</span>
                                        </>
                                        : null
                                }
                                {
                                    (parseInt(props.links.offset) + PAGINATION_SIZE < props.links.total) ?
                                        <a
                                            onClick={() => {moveByPage(Math.round(props.links.total / PAGINATION_SIZE) * PAGINATION_SIZE, PAGINATION_SIZE)}}
                                        >Last page</a>
                                        : null
                                }
                            </div>
                        </div>
                        {props.links.results?.map((link: any, index: number) => {
                            return <SmallBoxLinkCard
                                link={link}
                                index={index}
                            />
                        })}
                    </>
                    :
                    <div>
                        <div style={{maxWidth: "700px", overflowWrap: "break-word"}} >
                            Oops, we could not find any link.<br/>
                            Let's make a new one right now!</div>
                        <LinkForm />
                    </div>
            }
        </>
    )
}
export default LinksListing;