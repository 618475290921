import {Router} from "@reach/router";

import './App.css';
import HomePage from "./pages/home";
import LinkBoxPage from "./pages/linkBox";
import React, {useEffect} from "react";
import NavBar from "./components/navbar";
import PageNotFound from "./pages/pageNotFound/pageNotFound";
import CreatedBoxLinkPage from "./pages/createdBoxLink";
import LinkUnlockPage from "./pages/linkUnlock";
import asyncLocalStorage from "./hooks/asyncLocalStorage";
import {uuidv4} from "./utils/functions";
import FooterBar from "./components/footerBar";
import PromoPage from "./pages/promo";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Loader from "./components/loader";
import MyLinksListingPage from "./pages/myLinks";
import PublicListingPage from "./pages/publicLinks";
import Consent from "./components/consent";
import {getCookieConsentValue} from "react-cookie-consent";
import CookiesPolicy from "./pages/cookiesPolicy";
import {useDispatch} from "react-redux";
import {loginTgUserAction} from "./actions/authActions";

function App() {
    const dispatch = useDispatch();

    useEffect(() => {
        document.documentElement.style.setProperty('--bs-body-font-family',
            'Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji');
        document.title = "BOXLINK";
        if (localStorage.getItem('uuid') === null && getCookieConsentValue()) {
            asyncLocalStorage.setItem('uuid', uuidv4())
        }
        const tgUser = localStorage.getItem('tgUser');
        if (tgUser){
            dispatch(loginTgUserAction(JSON.parse(tgUser)));
        }
    })
  return (
    <div className="App">
      <BrowserRouter>
          <Consent />
          <NavBar />
          <Routes>
              <Route path={"/"} element={<HomePage />} />
                  <Route path={"/created-box-link"} element={<CreatedBoxLinkPage />} />
                  <Route path={"/my-links"} element={<MyLinksListingPage />} />
                  <Route path={"/public-links"} element={<PublicListingPage />} />
                  <Route path={"/l/:linkBoxId"} element={<LinkBoxPage />} />
                  <Route path={"/unlock-link/:linkBoxId"} element={<LinkUnlockPage />} />
                  <Route path={"/promo"} element={<PromoPage />} />
                  <Route path={"/404"} element={<PageNotFound />} />
                  <Route path={"/cookies-policy"} element={<CookiesPolicy />} />
                  <Route path={"*"} element={<PageNotFound />} />
          </Routes>
      </BrowserRouter>
      <FooterBar />

    </div>
  );
}

export default App;
