import React, {useEffect} from "react";
import {getPublicLinksListing} from "../../actions/linksListingActions";
import {useDispatch, useSelector} from "react-redux";
import {AppStateType} from "../../reducers";
import Container from "react-bootstrap/Container";
import {PAGINATION_SIZE} from "../../constants/constants";
import LinksListing from "../../components/linksListing";

const PublicListingPage = () => {
    const {isFetching, publicLinks} = useSelector<AppStateType, any>((state: AppStateType) => state.linksListing);
    const dispatch = useDispatch()

    useEffect(() => {
        if (!isFetching) {
            dispatch(getPublicLinksListing(0, PAGINATION_SIZE))
        }
    }, [])


    return (
        <section className={"h-100 mt-7"} >
            <Container fluid={"sm"}>
                <h3 className={"mb-4 custom-h3"}>Public Boxlinks</h3>
                <div className={"d-flex flex-wrap justify-content-around"}>
                    <LinksListing
                        links={publicLinks}
                        listingCallback={getPublicLinksListing}
                    />
                </div>
            </Container>
        </section>
    )
}
export default PublicListingPage;