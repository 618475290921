import React, {useEffect, useRef, useState} from "react";
import Popover from "react-bootstrap/Popover";
import {Button} from "react-bootstrap";
import "./popover.scss";
import {useDispatch} from "react-redux";
import {removeError} from "../../actions/linksActions";

const ErrorPopover = (props: any) => {
    const [show, setShow] = useState(true);
    const ref: any | null = useRef(null);
    const dispatch = useDispatch();

    const hide = () => {
        setShow(false);
        dispatch(removeError(props.index));
    }
    useEffect(() => {
        setTimeout(hide, 49000);
    }, [])

    return  (
        <Popover id="popover-basic"
                 show={show}
                 ref={ref}
                 placement={'auto'}
                 className={show ? "fade-out" : "hidden"}
                 bsPrefix={" error-modal popover"}
        >
            <Popover.Body>
                <div className={"ms-1"}>{props.error}</div>
                <Button aria-label="Close"
                        className="icon-close-button"
                        size={"sm"}
                        variant={"light"}
                        onClick={() => hide()}>
                    x
                </Button>
            </Popover.Body>
        </Popover>
    )
}
export default ErrorPopover;