
import React, {FC} from "react";

import Container from "react-bootstrap/Container";
import LinkUnlockForm from "../../components/linkUnlockForm";
import {useParams} from "react-router-dom";


interface LinkBoxTypes {
    linkBoxId?: any;
}

const LinkUnlockPage: FC<LinkBoxTypes> = () => {
    let params = useParams();
    return (
        <Container fluid={"sm"}>
            <LinkUnlockForm
                linkBoxId={params.linkBoxId}
            />
        </Container>
    )

}

export default LinkUnlockPage;