import {LinksActionTypes} from "../actions/linksActions";
import {Reducer} from "redux";

export type InitialStateType = {
    isFetching: boolean;
    errors: any;
    links: Array<any>;
    result: any;
    currentLink: any;
    unlockedLink: any;
    createdBoxLink: any;
}

const emptyCurrentLink = {
    isEditable: false,
    description: null,
    id: null,
    images: null,
    isPrivate: null,
    links: null,
    name: null,
    shortLink: null,
    validUntil: null
}
const emptyCreatedBoxLink = {
    isEditable: false,
    access_token: null,
    description: null,
    id: null,
    images: null,
    isPrivate: null,
    links: null,
    name: null,
    shortLink: null,
    validUntil: null
}

const initialState: InitialStateType = {
    isFetching: false,
    errors: {} as any,
    links: []  as any[],
    result: null,
    currentLink: emptyCurrentLink,
    createdBoxLink: emptyCreatedBoxLink,
    unlockedLink: null,
}

export const links = (
    state = initialState, action: LinksActionTypes
): InitialStateType => {
    switch(action.type){
        case "NEW_LINK_BOX_SUCCESS":
            return {
                ...state,
                isFetching: false,
                result: action.result,
                errors: {}
            }
        case "NEW_LINK_BOX_REQUEST":
            return {
                ...state,
                isFetching: true
            }
        case "NEW_LINK_BOX_ERROR":
            return {
                ...state,
                isFetching: false,
                errors: action.errors
            }
        case "UPDATED_BOX_LINK_REQUEST":
            return {
                ...state,
                isFetching: true
            }
        case "UPDATED_BOX_LINK_SUCCESS":
            return {
                ...state,
                isFetching: false,
                currentLink: action.result,
                errors: {}
            }
        case "UPDATED_BOX_LINK_ERROR":
            return {
                ...state,
                isFetching: false,
                currentLink: emptyCurrentLink,
                errors: action.error
            }
        case "DELETE_BOX_LINK_REQUEST":
            return {
                ...state,
                isFetching: true
            }
        case "DELETE_BOX_LINK_SUCCESS":
            return {
                ...state,
                isFetching: false,
                currentLink:  emptyCurrentLink
            }
        case "DELETE_BOX_LINK_ERROR":
            return {
                ...state,
                isFetching: false,
                errors: action.error
            }
        case "GET_LINK_REQUEST":
            return {
                ...state,
                isFetching: true,
                currentLink: {}
            }
        case "GET_LINK_SUCCESS":
            return {
                ...state,
                isFetching: false,
                currentLink: action.result,
                errors: {}
            }
        case "GET_LINK_ERROR":
            return {
                ...state,
                isFetching: false,
                errors: action.errors,
                currentLink: {}
            }
        case "SET_LINK_ERRORS":
            return {
                ...state,
                errors: action.errors
            }
        case "NEWLY_CREATED_BOX_LINK":
            return {
                ...state,
                isFetching: false,
                createdBoxLink: action.createdBoxLink,
                currentLink: action.createdBoxLink,
                errors: {}
            }
        case "UNLOCK_BOX_LINK_SUCCESS":
            return {
                ...state,
                isFetching: false,
                unlockedLink: action.result,
                currentLink: action.result,
                errors: {}
            }
        case "UNLOCK_BOX_LINK_ERROR":
            return {
                ...state,
                isFetching: false,
                errors: action.error
            }
        case "UNLOCK_BOX_LINK_REQUEST":
            return {
                ...state,
                isFetching: true
            }
        default:
            return state
    }
}