import React, {FC} from "react";
import {RouteComponentProps} from "@reach/router";
import CreatedBoxLink from "../../components/newlyCreatedBoxLink";
interface Interface extends RouteComponentProps {}


const CreatedBoxLinkPage: FC<Interface> = () => {

    return  (
        <CreatedBoxLink />
    )
}
export default CreatedBoxLinkPage;