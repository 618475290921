import {LinksListingActionTypes} from "../actions/linksListingActions";
import {
    MY_LINKS_REQUEST,
    MY_LINKS_REQUEST_ERROR,
    MY_LINKS_REQUEST_SUCCESS,
    PUBLIC_LINKS_LISTING_ERROR,
    PUBLIC_LINKS_LISTING_REQUEST,
    PUBLIC_LINKS_LISTING_SUCCESS
} from "../constants/actionTypes";
import {PAGINATION_SIZE} from "../constants/constants";

type LinksListingType = {
    offset: number,
    limit: number,
    results: Array<any>
}
const emptyListing: LinksListingType = {
    offset: 0,
    limit: PAGINATION_SIZE,
    results: []
}

export type InitialStateType = {
    isFetching: boolean;
    errors: any;
    publicLinks: LinksListingType;
    offset: number | null;
    limit: number | null;
    myLinks: LinksListingType
}
const initialState: InitialStateType = {
    isFetching: false,
    errors: {},
    publicLinks: emptyListing ,
    offset: null,
    limit: null,
    myLinks: emptyListing
}
export const linksListing =(
    state = initialState, action: LinksListingActionTypes
) => {
    switch (action.type) {
        case MY_LINKS_REQUEST:
            return {
                ...state,
                isFetching: true
            }
        case MY_LINKS_REQUEST_SUCCESS:
            return {
                ...state,
                isFetching: false,
                myLinks: action.response
            }
        case MY_LINKS_REQUEST_ERROR:
            return {
                ...state,
                isFetching: false,
                errors: action.error
            }
        case PUBLIC_LINKS_LISTING_REQUEST:
            return {
                ...state,
                isFetching: true
            }
        case PUBLIC_LINKS_LISTING_ERROR:
            return {
                ...state,
                isFetching: false,
                errors: action.errors
            }
        case PUBLIC_LINKS_LISTING_SUCCESS:
            return {
                ...state,
                isFetching: false,
                publicLinks: action.publicLinks
            }
        default:
            return state
    }
}