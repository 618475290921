import React, {useEffect} from "react";
import {getMyLinksList, getPublicLinksListing} from "../../actions/linksListingActions";
import {useDispatch, useSelector} from "react-redux";
import {AppStateType} from "../../reducers";
import Container from "react-bootstrap/Container";
import SmallBoxLinkCard from "../../components/smallBoxLinkCard";
import LinkForm from "../../components/linkForm";
import "./my-links.scss";
import {PAGINATION_SIZE, self_host} from "../../constants/constants";
import addIcon from "../../resources/img/boxlink-add.svg";

const MyLinksListingPage = () => {
    const {isFetching, myLinks} = useSelector<AppStateType, any>((state: AppStateType) => state.linksListing);
    const dispatch = useDispatch()

    useEffect(() => {
        if (!isFetching) {
            dispatch(getMyLinksList())
        }
    }, [])


    const moveByPage = (offset: number, limit: number) => {
        if (offset > myLinks.total){
            offset = myLinks.total
        }
        if (offset === myLinks.total){
            offset = offset - limit
        }
        dispatch(getMyLinksList(offset, limit));
    }

    return (
        <section className={"h-100 mt-7"} >
            <Container fluid={"sm"}>
                <h3 className={"mb-4 custom-h3"}>My Boxlinks</h3>
                <div className={"d-flex flex-wrap justify-content-around"}>
                    {myLinks.results.length > 0 ?
                        <>
                            <div className={"w-100 d-flex justify-content-between paginator "}>
                                <div>
                                        <a
                                            onClick={() => {moveByPage(0, PAGINATION_SIZE)}}
                                            style={{visibility: myLinks.offset > 0 ? "visible" : "hidden"}}
                                        >First page </a>
                                        <span
                                            style={{visibility: parseInt(myLinks.offset) - PAGINATION_SIZE >= 0 ? "visible" : "hidden"}}
                                        >|</span>
                                        <a
                                            onClick={() => {moveByPage(
                                                myLinks.offset - PAGINATION_SIZE, PAGINATION_SIZE
                                            )}}
                                            style={{visibility: parseInt(myLinks.offset) - PAGINATION_SIZE >= 0 ? "visible" : "hidden"}}
                                        >Back</a>
                                </div>
                                <div>
                                    <a href={self_host}  target={"_blank"} className={"font-color-blue create-new-button"}>
                                        <span>Create new</span><img src={addIcon} className={"add-icon"}/>
                                    </a>
                                </div>
                                <div>
                                    <a
                                        onClick={() => {moveByPage(
                                            myLinks.offset + PAGINATION_SIZE, PAGINATION_SIZE
                                        )}}
                                        style={{visibility: parseInt(myLinks.offset) + PAGINATION_SIZE < myLinks.total ? "visible" : "hidden"}}
                                    >Next</a>
                                    <span
                                        style={{visibility: parseInt(myLinks.offset) + PAGINATION_SIZE < myLinks.total ? "visible" : "hidden"}}
                                    >|</span>
                                    <a
                                        onClick={() => {moveByPage(Math.round(myLinks.total / PAGINATION_SIZE) * PAGINATION_SIZE, PAGINATION_SIZE)}}
                                        style={{visibility: parseInt(myLinks.offset) + PAGINATION_SIZE < myLinks.total ? "visible" : "hidden"}}
                                    >Last page</a>
                                </div>
                            </div>
                            {myLinks.results?.map((link: any, index: number) => {
                                    return <SmallBoxLinkCard
                                        link={link}
                                        index={index}
                                    />
                                })}
                        </>
                    :
                        <div>
                            <div style={{maxWidth: "700px", overflowWrap: "break-word"}} >
                            Oops, we could not find any link, created from your device.<br/>
                            Let's make a new one right now!</div>
                            <LinkForm />
                        </div>
                    }
                </div>
            </Container>
        </section>
    )
}
export default MyLinksListingPage;