import "./promo.scss";
import SEO from "../seo";
import React from "react";

const PromoComponent = () => {
    return (
        <>
            <SEO
                title={"Combine content across the Web and share it with your audience. Box you links."}
                url={"https://boxlink.one"}
            />
        <div
            className={"promo-container"}
        >
            <div className={"box"}></div>
            <h1
                className={"text"}
            >BOXLINK</h1>

        </div>
        </>
    )
}
export default PromoComponent;
