import React, {useRef, useLayoutEffect} from 'react'

export interface TelegramUser {
    id: number
    first_name: string
    username: string
    photo_url: string
    auth_date: number
    hash: string
}

interface Props {
    botName: string
    usePic?: boolean
    className?: string
    cornerRadius?: number
    requestAccess?: boolean
    dataOnAuth: (user: TelegramUser) => void
    buttonSize?: 'large' | 'medium' | 'small'
}

declare global {
    interface Window {
        TelegramLoginWidget: {
            dataOnAuth: (user: TelegramUser) => void
        }
    }
}

const TelegramLoginButton: React.FC<Props> = props => {
    const ref = useRef<HTMLDivElement| null>(null)

    const {
        usePic = false,
        botName,
        className = 'tgme_widget_login_button',
        buttonSize = 'large',
        dataOnAuth,
        cornerRadius,
        requestAccess = true
    } = props

    useLayoutEffect(() => {

        if (ref.current === null) return

        window.TelegramLoginWidget = {
            dataOnAuth: (user: TelegramUser) => dataOnAuth(user)
        }

        const script = document.createElement('script')
        script.src = 'https://telegram.org/js/telegram-widget.js?16'
        script.setAttribute('data-telegram-login', botName)
        script.setAttribute('data-size', buttonSize)

        if (cornerRadius !== undefined) {
            script.setAttribute('data-radius', cornerRadius.toString())
        }

        if (requestAccess) {
            script.setAttribute('data-request-access', 'write')
        }

        script.setAttribute('data-userpic', usePic.toString())
        script.setAttribute('data-onauth', 'TelegramLoginWidget.dataOnAuth(user)')
        script.async = true
        if (ref.current && 'appendChild' in ref?.current){
            console.log(ref.current);
            ref?.current!.appendChild(script)
        }
    }, [
        botName,
        buttonSize,
        cornerRadius,
        dataOnAuth,
        requestAccess,
        usePic,
        ref
    ])

    return <div ref={ref} className={className} />
}

// TelegramLoginButton.propTypes = {
//     botName: PropTypes.string.isRequired,
//     usePic: PropTypes.bool,
//     className: PropTypes.string,
//     cornerRadius: PropTypes.number,
//     requestAccess: PropTypes.bool,
//     dataOnauth: PropTypes.func.isRequired,
//     buttonSize: PropTypes.oneOf(['large', 'medium', 'small'])
// }

export default TelegramLoginButton