import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import Container from "react-bootstrap/Container";
import logo from "../../resources/img/logo.png";
import svg_logo from "../../resources/img/boxlink_logo.svg";
import {useDispatch, useSelector} from "react-redux";
import {AppStateType} from "../../reducers";
import {linksListing} from "../../reducers/linksListingReducer";
import {Link} from "react-router-dom";
import "./navbar.scss";
import TelegramLoginButton from "../tgLoginButton";
import React, {useEffect, useState} from "react";
import LoggedInUser from "../loggedInUser";
import {isMobile} from "react-device-detect";
import {NavDropdown} from "react-bootstrap";
import {loginTgUserAction} from "../../actions/authActions";


const NavBar = () => {
    const {myLinks} = useSelector<AppStateType, any>((state: AppStateType) => state.linksListing)
    const [renderLogin, setRenderLogin] = useState(false);
    const [renderLoggedIdUser, setRenderLoggedInUser] = useState(false);
    const [showMobileNav, setShowMobileNav] = useState(false);
    const [tgUser, setTgUser] = useState<any>(null);
    const dispatch = useDispatch();

    useEffect(() => {
        const tg_stored = localStorage.getItem('tgUser');
        if (tg_stored){
            const tg_user =  JSON.parse(tg_stored);
            setTgUser(tg_user)
        }
    }, [])

    const handleClose = () => setShowMobileNav(false);
    const toggleMobileMenu = () => {
        setShowMobileNav(!showMobileNav);
    }
    function onTelegramAuth(user: any){
        const us = JSON.stringify(user);
        localStorage.setItem("tgUser", us);
        setRenderLogin(false);
        setRenderLoggedInUser(true);
        dispatch(loginTgUserAction(JSON.parse(us)))
    }

    const logout = () => {
        localStorage.removeItem("tgUser");
        setRenderLogin(!localStorage.getItem('tgUser'));
    }

    useEffect(()=>{
        setRenderLogin(!localStorage.getItem('tgUser'));
    }, [renderLogin, renderLoggedIdUser, setRenderLogin])


    return (
        <Navbar
            // fixed="top"
            //     bg="light"
                // variant="light"
                className={"bg-blue ft-medium ft-size-27"}
                expand={!isMobile}
        >
            <Container>
                <Navbar.Brand>
                    <a href={"/"}>
                        <img src={svg_logo}
                         width="50"
                         height="50"
                         className="App-logo d-inline-block align-top" alt="logo"/>
                    </a>
                </Navbar.Brand>
                {!isMobile ?
                <div className={isMobile ? 'd-none' : ""} >
                    <Link to={"/public-links"}
                          className={`my-links-button`}
                    >Public Boxlinks</Link>
                </div>
                    : null
                }
                {
                    myLinks && !isMobile ?
                        <div className={isMobile ? 'd-none' : ""}>
                            <Link
                                to={"/my-links"}
                                className={`my-links-button`}
                                >My Boxlinks</Link>
                        </div>
                        : null
                }
                <Navbar.Toggle
                    onClick={toggleMobileMenu}
                    className={isMobile ? 'sandwich' : ""}
                    aria-controls="mobile-nav"
                />

                <Offcanvas
                    id={"mobile-nav"}
                    placement={"top"}
                    onHide={handleClose}
                    show={showMobileNav}
                    scroll={false}
                    className={"topNavMobile"}
                >
                    <Offcanvas.Body>
                        <div className={"d-flex flex-column"}>
                            {!renderLogin ?
                                <div className={"dropdown-item"}>
                                    Welcome, {tgUser?.first_name ? tgUser.first_name : tgUser?.username ? tgUser.username : <></> }
                                </div>
                                :
                                <TelegramLoginButton
                                    botName="bxlinkbot"
                                    dataOnAuth={(user) => onTelegramAuth(user)}
                                    cornerRadius={0}
                                />
                            }
                            <hr className={"mt-2"}/>
                            <Link to={"/public-links"}
                                  className={`my-links-button dropdown-item `}
                                  onClick={handleClose}
                            >Public Boxlinks</Link>
                            {
                                myLinks ? <Link
                                    to={"/my-links"}
                                    className={`my-links-button dropdown-item `}
                                    onClick={handleClose}
                                >My Boxlinks</Link> : null
                            }
                            {
                                renderLogin ?
                                    null
                                    :
                                    <div className={"dropdown-item"} onClick={() => logout()}>Logout</div>
                            }
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>

                {
                    renderLogin && !isMobile ?
                        <TelegramLoginButton
                            botName="bxlinkbot"
                            dataOnAuth={(user) => onTelegramAuth(user)}
                            cornerRadius={0}
                        />
                    :
                        !isMobile ? <LoggedInUser logout={logout}/> : null
                }
            </Container>
        </Navbar>
    )
}
export default NavBar;