import React from "react";
import Container from "react-bootstrap/Container";
import Step from "./step";

import mailImage from '../../resources/img/mail.png';
import {
    AiOutlineClockCircle,
    CgNametag,
    GrUpdate,
    RiLockPasswordLine
} from "react-icons/all";


const InstructionsSection = () => {
    return (
        <section className={"w-100 pt-5 pb-5"}

        >
            <Container fluid={"sm"}>
                <h3 className={"mb-4 custom-h3"}>How to share content with one click?</h3>
                <div className={"fs-4"}>Pack it with Boxlink</div>
                <Step
                    title={"Personal or business use"}
                    description={"You have content from different websites (Amazon products, documents, or articles)\n" +
                    "                    you need to share with your friends or colleagues.\n" +
                    "                    Or you have scheduled a meeting via Zoom, prepared several shared files,\n" +
                    "                    and need to send links to them to dozens of people.\n"}

                    contentSide={"left"}
                    image={mailImage}
                    even={true}
                >

                </Step>
                <Step
                    title={"Various content in one place"}
                    description={"You can pack them up in a <strong>Boxlink</strong> and forward a single short link. " +
                    "Your audience will always be able to see the <strong>Boxlink</strong> content and follow the links you " +
                    "attached and described."
                    }
                    image={"https://static.tildacdn.com/tild3933-3239-4565-b961-356366626465/2.svg"}
                    contentSide={"right"}
                    iconBlock={<CgNametag
                        size={"10rem"}
                    />}


                />
                <Step
                    title={"Always up to date"}
                    description={"When you need to add a new document or change the Zoom conference link,\n" +
                    "you do not need to resend the whole pack of links again. You only update the <strong>Boxlink</strong>."}
                    image={"https://static.tildacdn.com/tild3933-3239-4565-b961-356366626465/2.svg"}
                    contentSide={"left"}
                    iconBlock={<GrUpdate
                        size={"8rem"}
                    />}
                    even={true}
                />
                <Step
                    title={"Public or private"}
                    description={"If you want your collection to be private, " +
                    "you can protect your <strong>Boxlink</strong> with a password."}
                    image={"https://static.tildacdn.com/tild3933-3239-4565-b961-356366626465/2.svg"}
                    contentSide={"right"}
                    iconBlock={<RiLockPasswordLine
                        size={"8rem"}
                    />}

                />
                <Step
                    title={"Timed and personalised"}
                    description={"<strong>Boxlinks</strong> created from your device are for now editable " +
                    "<strong>only</strong> from it. So, there is no need to create accounts or sign up for anything." +
                    " Together with that such <strong>Boxlinks</strong> have limited lifetime currently set up for <strong>10 days</strong>."}
                    contentSide={"left"}
                    iconBlock={<AiOutlineClockCircle
                        size={"8rem"}
                    />}
                    even={true}
                />
            </Container>
        </section>
    )
}
export default InstructionsSection;