import {endpoint} from "../constants/constants";
import myAxios from "../utils/axios";

async function loginWithTg(user: any) {
    const url = `${endpoint}/api/auth/tg`;
    const payload = {
        "cookie_id": localStorage.getItem("uuid"),
        "tg_user": user
    };
    try {
        const response = await myAxios.POST(
            url, payload
        )
        return response.data;
    } catch (e: any){

    }
}
export {
    loginWithTg,
}