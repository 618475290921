import {useSelector} from "react-redux";
import {AppStateType} from "../../reducers";
import LinkCard from "../linkCard";
import React, {useEffect} from "react";
import Container from "react-bootstrap/Container";
import {useNavigate} from "react-router-dom";

const CreatedBoxLink = () => {
    const {isFetching, createdBoxLink} = useSelector((state: AppStateType) => state.links);
    const navigate = useNavigate();
    useEffect(() => {
        if (createdBoxLink.id === null) {
            navigate('/')
        }
    })

    return (
        <section className={"h-100 mt-7"} >
            <Container fluid={"sm"}>
                <LinkCard createdLink={createdBoxLink?.link}/>
            </Container>
        </section>)
}
export default CreatedBoxLink;