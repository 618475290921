import Container from "react-bootstrap/Container";
import {FC} from "react";
import {RouteComponentProps} from "@reach/router";
interface Interface extends RouteComponentProps {}


const PageNotFound: FC<Interface> = () => {
    return (
        <Container>
            <div className={"fs-1"}>404</div>
            <h4>Sorry, but we could not find the page you were looking for.</h4>
        </Container>
    )
}
export default PageNotFound;