import {AppStateType} from "../../reducers";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect} from "react";
import {getMyLinksList, getPublicLinksListing} from "../../actions/linksListingActions";
import Container from "react-bootstrap/Container";
import "./linksListing.scss";
import SmallBoxLinkCard from "../smallBoxLinkCard";

const  PromoLinksListing = () => {

    const {isFetching, publicLinks, errors} = useSelector<AppStateType, any>((state: AppStateType) => state.linksListing);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isFetching) {
            dispatch(getPublicLinksListing(0, 3))
            dispatch(getMyLinksList())
        }
    }, [])
    return (
        <section className={"w-100 pt-5 pb-5"}
            style={{background: "lightgrey"}}
        >
            <Container fluid={"sm"}>
            <h3 className={"mb-4 custom-h3"}>Popular public Boxlinks</h3>
                <div className={"d-flex links-holder"}>
                    {publicLinks.results.map((link: any, index: number) => {
                        return <SmallBoxLinkCard
                            link={link}
                            index={index}
                        />
                    })}

                </div>
        </Container>
        </section>
    )
}
export default PromoLinksListing;