import {ThunkAction} from "redux-thunk";
import {AppStateType} from "../reducers";
import {
    LOGIN_TG_USER_REQUEST_TYPE,
    LOGIN_TG_USER_REQUEST_TYPE_ERROR,
    LOGIN_TG_USER_REQUEST_TYPE_SUCCESS
} from "../constants/actionTypes";
import * as AuthApi from "../api/auth";
import asyncLocalStorage from "../hooks/asyncLocalStorage";
import {uuidv4} from "../utils/functions";

export type AuthActionTypes =
    LoginTgUserRequestType
    | LoginTgUserSuccessType
    | LoginTgUserRequestErrorType;

type LoginTgUserRequestType = {
    type: typeof LOGIN_TG_USER_REQUEST_TYPE;
    isFetching: boolean;
}

const loginTgUserRequest = (): LoginTgUserRequestType => {
    return {
        type: LOGIN_TG_USER_REQUEST_TYPE,
        isFetching: true
    }
}
type LoginTgUserSuccessType = {
    type: typeof LOGIN_TG_USER_REQUEST_TYPE_SUCCESS,
    isFetching: boolean;
    uuid: string;
    tg_user: any;
}
const loginTgUserSuccess = (logged_in_tg_user: any): LoginTgUserSuccessType => {
    return  {
        type: LOGIN_TG_USER_REQUEST_TYPE_SUCCESS,
        isFetching: false,
        uuid: logged_in_tg_user.uuid,
        tg_user: logged_in_tg_user.tg_user
    }
}
type LoginTgUserRequestErrorType = {
    type: typeof LOGIN_TG_USER_REQUEST_TYPE_ERROR;
    isFetching: boolean;
    error: any;
}
const loginTgUserRequestError =(error: any): LoginTgUserRequestErrorType => {
    return {
        type: LOGIN_TG_USER_REQUEST_TYPE_ERROR,
        isFetching: false,
        error: error
    }
}

export const loginTgUserAction = (tg_user: any):
    ThunkAction<Promise<void>, AppStateType, undefined, AuthActionTypes> => async (dispatch, getState) => {
    dispatch(loginTgUserRequest());
    try {
        const response = await AuthApi.loginWithTg(tg_user);
        dispatch(loginTgUserSuccess(response));
        localStorage.setItem('uuid', response.uuid);
    } catch (e: any){
        dispatch(loginTgUserRequestError(e.response.data()))
    }

}
