import * as React from 'react';

import styled, { css, keyframes } from 'styled-components';
import {FC} from "react";

// Key Frames
const shimmerKeyFrame = keyframes`
   0%{
        background-position: 100% 80%;
    }
    100%{
        background-position: 0 80%;
    }
`
interface DescriptionInterface {
    shimmerKeyFrame?: string;
    loadingWidth?: number;
    loading?: boolean;
    className?: string;
    maxLine?: number;
    minLine?: number;
}
const loadingStyle = css<DescriptionInterface>`
  animation-duration: 1.4s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: ${shimmerKeyFrame};
  background: darkgray;
  background: linear-gradient(90deg, #cacaca 25%, #e4e4e4 37%, #f7f6f6 63%);
  background-size: 800px 100%;
  color: transparent;
  -webkit-line-clamp: 1;
  border-radius: 10px;
  width: ${(props ) => (props.loadingWidth ? `${100 / props.loadingWidth}%` : `100%`)};
`
interface UnstyledDescriptionInterface {
    loading?: boolean;
    loadingWidth1?: number;
    maxLine?: number | undefined;
    minLine?: number | undefined;
    className?: string;
}
const UnstyledDescription: FC<UnstyledDescriptionInterface> = ({ loading, loadingWidth1, maxLine, minLine, className, ...props}) => {
    return (
        <p {...props} className={className}>{props.children}</p>
    );
}

export const Description = styled(UnstyledDescription)<DescriptionInterface>`
  &&& {
    text-align: inherit;
    font-weight: inherit;
    font-family: inherit;
    color: inherit;
    margin: 0;
    ${(props ) => (props.loading && loadingStyle)}
  }
`

export default Description;
