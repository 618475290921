
import {Button, Modal} from "react-bootstrap";
import React from "react";
import asyncLocalStorage from "../../hooks/asyncLocalStorage";
import {uuidv4} from "../../utils/functions";


const CookieModal = (props:any)=> {

    const decline = () => {
        asyncLocalStorage.setItem('cookies-ignored', true);
        props.closeCallback();
    }
    const agree = () => {
        asyncLocalStorage.delItem('cookies-ignored');
        let someDate = new Date();
        let numberOfDaysToAdd = 730;
        const result = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
        document.cookie = `boxlink-cookie=true; expires=${result}; samesite=lax`;
        asyncLocalStorage.setItem('uuid', uuidv4())
        props.closeCallback();
        props.acceptCallback(props.form);
    }
    return (
        <>
            <Modal show={props.show} >
                <Modal.Header >
                    <Modal.Title>Limited functionality</Modal.Title>
                </Modal.Header>
                <Modal.Body>You did not accept or declined use of basic cookies.
                    Unfortunately, you will not be able to manage saved links without cookie consent. We do not track
                    or store your personal information,
                    but cookies help us run this site without login information.</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={decline}>Ingore cookies
                    </Button>
                    <Button variant="primary" onClick={agree}>
                        Accept cookies
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default CookieModal;