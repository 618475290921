import React, {FC} from "react";
import {RouteComponentProps} from "@reach/router";


import PromoComponent from "../../components/promoComponent";

interface Interface extends RouteComponentProps {}

const PromoPage: FC<Interface> = () => {

    return (
            <PromoComponent />
    )
}
export default PromoPage;