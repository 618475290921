import {
    MY_LINKS_REQUEST, MY_LINKS_REQUEST_ERROR, MY_LINKS_REQUEST_SUCCESS,
    PUBLIC_LINKS_LISTING_ERROR,
    PUBLIC_LINKS_LISTING_REQUEST,
    PUBLIC_LINKS_LISTING_SUCCESS
} from "../constants/actionTypes";
import {ThunkAction} from "redux-thunk";
import {AppStateType} from "../reducers";
import * as LinkApi from "../api/links";
import {PAGINATION_SIZE} from "../constants/constants";

export type LinksListingActionTypes =
    PublicLinksListingRequestType
    | PublicLinksListingErrorType
    | PublicLinksListingSuccessType
    | GetMyLinksRequestType
    | GetMyLinksListErrorType
    | GetMyLinksSuccessType;

type PublicLinksListingRequestType = {
    type: typeof PUBLIC_LINKS_LISTING_REQUEST;
}
type PublicLinksListingErrorType = {
    type: typeof PUBLIC_LINKS_LISTING_ERROR;
    errors: any;
}
type PublicLinksListingSuccessType = {
    type: typeof PUBLIC_LINKS_LISTING_SUCCESS;
    publicLinks: Array<any>;
}

const publicLinkRequestError = (errors: any): PublicLinksListingErrorType => {
    return {
        type: PUBLIC_LINKS_LISTING_ERROR,
        errors: errors
    }
}
const publicLinkRequestSuccess = (links: Array<any>): PublicLinksListingSuccessType => {
    return {
        type: PUBLIC_LINKS_LISTING_SUCCESS,
        publicLinks: links,
    }
}
const publicLinksListingRequest = (): PublicLinksListingRequestType => {
    return {
        type: PUBLIC_LINKS_LISTING_REQUEST
    }
}
export const getPublicLinksListing = (offset: number = 0, limit:  number = PAGINATION_SIZE): ThunkAction<Promise<void>, AppStateType, undefined, LinksListingActionTypes> => async (dispatch) => {
    dispatch(publicLinksListingRequest());
    try{
        const response = await LinkApi.getPublicLinks(offset, limit);
        dispatch(publicLinkRequestSuccess(response));
    }catch (e) {
        dispatch(publicLinkRequestError(e))
    }
}
type GetMyLinksRequestType = {
    type: typeof MY_LINKS_REQUEST
}
const getMyLinksRequest = ():GetMyLinksRequestType => {
    return  {
        type: MY_LINKS_REQUEST
    }
}
type GetMyLinksListErrorType = {
    type: typeof MY_LINKS_REQUEST_ERROR,
    error: any
}
const getMyLinksListError = (error: any):GetMyLinksListErrorType => {
    return {
        type: MY_LINKS_REQUEST_ERROR,
        error: error
    }
}

type GetMyLinksSuccessType = {
    type: typeof MY_LINKS_REQUEST_SUCCESS,
    response: any
}
const getMyLinksSuccess = (response: any): GetMyLinksSuccessType => {
    return {
        type: MY_LINKS_REQUEST_SUCCESS,
        response: response
    }
}
export const getMyLinksList = (offset?: number, limit: number = PAGINATION_SIZE):
    ThunkAction<Promise<void>, AppStateType, undefined, LinksListingActionTypes> => async (dispatch) => {
    dispatch(getMyLinksRequest());
    try {
        const response = await LinkApi.getMyLinks(offset, limit);
        dispatch(getMyLinksSuccess(response));
    } catch (e) {
        dispatch(getMyLinksListError(e))
    }
}