import React, {FC} from "react";

import {RouteComponentProps} from "@reach/router";

import LinkForm from "../../components/linkForm";

import Container from "react-bootstrap/Container";
import PromoLinksListing from "../../components/promoLinksListing";
import TopLandingComponent from "../../components/topLandingComponent";
import FooterBar from "../../components/footerBar";
import InstructionsSection from "../../components/insntructionsSection";

interface Interface extends RouteComponentProps {}


const HomePage: FC<Interface> = () => {

    return (
        <>
            <TopLandingComponent />
            <PromoLinksListing />
            <InstructionsSection />
            <section className={"w-100 pb-5 d-flex align-items-center"}
                style={{background: "lightgrey"}}
            >
                <LinkForm id={2} />
            </section>
        </>
    )
}
export default HomePage;