import CookieConsent, {getCookieConsentValue} from "react-cookie-consent";
import React from "react";
import asyncLocalStorage from "../../hooks/asyncLocalStorage";
import {uuidv4} from "../../utils/functions";


const Consent = () => {

    const setUuid = () => {
        if (localStorage.getItem('uuid') === null) {
            asyncLocalStorage.setItem('uuid', uuidv4());
            asyncLocalStorage.delItem('cookies-ignored');
        }
    }

    return (
        <CookieConsent
            location="bottom"
            buttonText="I accept cookies"
            cookieName="boxlink-cookie"
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={730}
            onAccept={setUuid}
            overlay={false}
        >This website uses cookies to enhance the user experience.{" "} Please, read our cookies policy <a href={"/cookies-policy"}>here</a>.
        </CookieConsent>
    )
}
export default Consent;