import React, {FC} from "react";

import LinkCard from "../../components/linkCard";
import Container from "react-bootstrap/Container";
import {useParams} from "react-router-dom";
import "./linkBox.scss";

interface LinkBoxTypes {
    linkBoxId?: any;
}

const LinkBoxPage: FC<LinkBoxTypes> = () => {
    let params = useParams();
    return (
        <section className={"h-100 link-section"} >
            <Container fluid={"sm"}>
                <LinkCard
                    linkBoxId={params.linkBoxId}
                />
            </Container>
        </section>
    )

}

export default LinkBoxPage;