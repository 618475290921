import React from "react";
import Helmet from "react-helmet";
import logo from '../../resources/img/boxlink_logo.svg'
const SEO = (props: any) => {

    const thumbnail = props.thumbnail ? props.thumbnail : logo;
    return (
        <Helmet>
            <title>{props.title}</title>
            <meta property="og:title" content={props.title} />
            <meta property="og:image" content={`https://boxlink.one${thumbnail}`} />
            <meta property="og:url" content={props.url} />
            <meta property="og:type" content="website" />
        </Helmet>
    )
}
export default SEO;