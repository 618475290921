import React from "react";
import "./step.scss";


const Step = (props: any) => {

    const createMarkup = (content: any) => {
        return {__html: content}
    }

    const textBlock = (props: any) =>{
        return (
            <>
                <div><h3>{props.title}</h3></div>
                <div
                    className={"step mt-4"}
                    dangerouslySetInnerHTML={createMarkup(props.description)}
                    style={{fontSize: "1.2rem"}}
                ></div>
            </>
        )}
    const imageBlock = (image: string) => {
        return (
            <img src={image} className={"side-image"}/>
        )}

    const iconBlock = (icon: any) => {
        return (
            icon
        )
    }

    return (
        <div
            className={`d-flex flex-wrap step-section ${ props.even ? 'reverse': ''}`}
        >
            <div
                className={"side-block"}
            >
                {props.contentSide === 'left' ? textBlock(props) :
                    props?.iconBlock ? iconBlock(props.iconBlock) : imageBlock(props.image)}
            </div>
            <div className={"side-block"}
            >
                {props.contentSide === 'left' ?
                    props?.iconBlock ? iconBlock(props.iconBlock) :
                    imageBlock(props.image) : textBlock(props)}
            </div>
        </div>
    )
}
export default Step;