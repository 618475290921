export const NEW_LINK_BOX_REQUEST = 'NEW_LINK_BOX_REQUEST';
export const NEW_LINK_BOX_ERROR = 'NEW_LINK_BOX_ERROR';
export const NEW_LINK_BOX_SUCCESS = 'NEW_LINK_BOX_SUCCESS';

export const GET_LINK_REQUEST = 'GET_LINK_REQUEST';
export const GET_LINK_SUCCESS = 'GET_LINK_SUCCESS';
export const GET_LINK_ERROR = 'GET_LINK_ERROR';

export const NEWLY_CREATED_BOX_LINK = 'NEWLY_CREATED_BOX_LINK';
export const SET_LINK_ERRORS = 'SET_LINK_ERRORS';

export const UPDATED_BOX_LINK_REQUEST = 'UPDATED_BOX_LINK_REQUEST';
export const UPDATED_BOX_LINK_SUCCESS = 'UPDATED_BOX_LINK_SUCCESS';
export const UPDATED_BOX_LINK_ERROR = 'UPDATED_BOX_LINK_ERROR';

export const DELETE_BOX_LINK_REQUEST = 'DELETE_BOX_LINK_REQUEST';
export const DELETE_BOX_LINK_SUCCESS = 'DELETE_BOX_LINK_SUCCESS';
export const DELETE_BOX_LINK_ERROR = 'DELETE_BOX_LINK_ERROR';

export const UNLOCK_BOX_LINK_REQUEST = 'UNLOCK_BOX_LINK_REQUEST';
export const UNLOCK_BOX_LINK_SUCCESS = 'UNLOCK_BOX_LINK_SUCCESS';
export const UNLOCK_BOX_LINK_ERROR = 'UNLOCK_BOX_LINK_ERROR';

export const MY_LINKS_REQUEST = 'MY_LINKS_REQUEST';
export const MY_LINKS_REQUEST_SUCCESS = 'MY_LINKS_REQUEST_SUCCESS';
export const MY_LINKS_REQUEST_ERROR = 'MY_LINKS_REQUEST_ERROR';

export const PUBLIC_LINKS_LISTING_REQUEST = 'PUBLIC_LINKS_LISTING_REQUEST';
export const PUBLIC_LINKS_LISTING_SUCCESS = 'PUBLIC_LINKS_LISTING_SUCCESS';
export const PUBLIC_LINKS_LISTING_ERROR = 'PUBLIC_LINKS_LISTING_ERROR';


export const LOGIN_TG_USER_REQUEST_TYPE = 'LOGIN_TG_USER_REQUEST_TYPE';
export const LOGIN_TG_USER_REQUEST_TYPE_ERROR = 'LOGIN_TG_USER_REQUEST_TYPE_ERROR';
export const LOGIN_TG_USER_REQUEST_TYPE_SUCCESS = 'LOGIN_TG_USER_REQUEST_TYPE_SUCCESS';