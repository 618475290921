import React, {FC, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {deleteBoxLink, getLink} from "../../actions/linksActions";
import {AppStateType} from "../../reducers";
import {AiOutlineEdit, IoIosCopy, RiDeleteBin6Line, RiLockPasswordLine} from "react-icons/all";
import "./linkCard.scss";

import ShareBlock from "./share";
import {useNavigate} from "react-router-dom";
import LinkForm from "../linkForm";
import {self_host} from "../../constants/constants";
import ReactTinyLink from "../tinyLink";
// import {ReactTinyLink} from "ts-loader?react-tiny-link/src/ReactTinyLink";

interface LinkCardProps {
    linkBoxId?: any;
    createdLink?: any;
}

const LinkCard: FC<LinkCardProps> = ({linkBoxId, createdLink}) => {
    const {isFetching, currentLink, errors, createdBoxLink} = useSelector<AppStateType, any>((state: AppStateType) => state.links);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [editMode, setEditMode] = useState(false);
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        if  (isFetching){
            return
        } else{
            if (errors && errors.code === 401) {
                navigate(`/unlock-link/${linkBoxId}`)
            } else {
                if (currentLink.shortLink){
                    if (linkBoxId && currentLink.shortLink !== linkBoxId){
                        dispatch(getLink(linkBoxId))
                    }
                    return;
                }
                if (linkBoxId && createdBoxLink.id === null){
                    dispatch(getLink(linkBoxId));
                }
            }
        }
        }, [isFetching]
    )

    const copyToClipboard = (value: any, shortLink: string) => {
        try{
            navigator.clipboard.writeText(value);
        }catch (e) {
            const elem = document.createElement('textarea');
            elem.value = value;
            document.body.appendChild(elem);
            elem.select();
            document.execCommand('copy');
            document.body.removeChild(elem);
        }
        setCopied(true);
        setTimeout(() => setCopied(false), 1500);
    }

    const toggleEditForm = () => {
        setEditMode(!editMode);
    }
    const date = currentLink.validUntil ? new Date(Date.parse(currentLink.validUntil)).toUTCString() : null;

    const sendLinkDeleteEvent = () => {
        dispatch(deleteBoxLink(currentLink.shortLink))
    }
    function cutLink(protocol: string, hostName: string) {
        let link = `${protocol}/${hostName}...`;
        if (link.length > 15) {
            return `${link.substring(0, 15)}...`
        }
        return link
    }
    function evenOdd(index: number) {
        return index % 2 === 0;
    }

    return (
        <div className={"text-start m-auto link-card"}>
            {editMode ?
                <LinkForm id={1}
                    currentLink={currentLink}
                    callback={toggleEditForm}
                />
                :
                <>
                    <div className={"mb-2"}>
                        {createdLink ?
                            <div className={"mb-3"}>This is your new BoxLink. Please, copy it and forward to anyone who
                                should have access to it.<br/>
                                <a href={createdLink}>{createdLink}</a>
                                <span className={"copy-icon mx-3"}
                                      onClick={() => copyToClipboard(createdLink, createdBoxLink.shortLink)}><IoIosCopy/></span>
                            </div> : null}
                        <ShareBlock shortLink={currentLink.shortLink} description={currentLink.description}/>
                        <br/>
                        <h2>{currentLink?.name}
                            {currentLink.isPrivate ?
                                <span className={"copy-link-tooltip mx-2"}>
                                    <RiLockPasswordLine
                                        style={{fontSize: "1.5rem"}}
                                    />
                                    <span className={"link-tooltip"} style={{fontSize: "0.9rem"}}>This Boxlink is private</span>
                                </span>
                            : null}
                            <span className={"copy-link-tooltip mx-2"}>
                                <IoIosCopy
                                    className={"copy-icon font-color-blue"}
                                    onClick={() => copyToClipboard(`${self_host}/l/${currentLink.shortLink}`, "")}
                                    style={{fontSize: "1.5rem"}}
                                />
                                <span className={"link-tooltip"}
                                        style={{fontSize: "0.9rem"}}>Copy this Boxlink</span>
                            </span>
                            {currentLink.isEditable && !editMode ?
                                <>
                                    <span className={"copy-link-tooltip mx-2"}>
                                        <AiOutlineEdit
                                            className={"copy-icon font-color-blue"}
                                            onClick={() => toggleEditForm()}
                                            style={{fontSize: "1.5rem"}}
                                        /><span className={"link-tooltip"}
                                                style={{fontSize: "0.9rem"}}>This Boxlink is editable</span>
                                    </span>
                                    <span className={"copy-link-tooltip mx-2"}>
                                        <RiDeleteBin6Line
                                            className={"copy-icon font-color-blue"}
                                            onClick={() => sendLinkDeleteEvent()}
                                            style={{fontSize: "1.5rem"}}
                                        /><span className={"link-tooltip"}
                                                style={{fontSize: "0.9rem"}}>Delete this Boxlink</span>
                                    </span>
                                </>
                            : null}
                        </h2>
                    </div>


                    <div className={"link-number"} >{currentLink?.description}</div>
                    <div
                        className={"font-color-blue"}
                    >
                        {!!date ? `Valid until: ${date}` : null}
                    </div>
                    <div className={"d-flex flex-wrap links-wrapper"}>
                    {currentLink?.links?.map((link: any, index: number) => {
                        const url = new URL(link.link);
                        return <div className={`dyn-w-25 `}>
                            <div
                                className={"d-flex "}
                            >
                                <div className={"w-100 mb-1 card-description"}>
                                    <div
                                        style={{  fontSize: "1.2rem"}}
                                        className={"ft-medium"}
                                    >
                                        <span className={"font-color-blue"}>#{index + 1}</span>  {link.description}
                                        <br/>
                                    </div>
                                    <a
                                        rel={"noreferrer"}
                                        href={link.link} target={"_blank"}
                                    >
                                        {cutLink(url.protocol, url.hostname)}
                                    </a>

                                    <span className={"copy-icon mx-2 font-color-blue"}
                                          onClick={() => copyToClipboard(link.link, currentLink.shortLink)}><IoIosCopy/>
                                    {/*<AiOutlineCheckCircle*/}
                                    {/*    className={"copy-acknowledged fade-out-forwards"}*/}
                                    {/*    visibility={copied ? "visible" : "hidden"}*/}
                                    {/*/>*/}
                                    </span>

                                    <ReactTinyLink

                                        cardSize="large"
                                        showGraphic={true}
                                        maxLine={1} minLine={2} url={link.link}
                                    />
                                </div>
                            </div>
                        </div>
                    })}
                    </div>
                </>
            }
        </div>
    )
}
export default LinkCard;