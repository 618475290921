import LinkElement from "../linkFormElement";
import {Button, Form} from "react-bootstrap";
import React, { useEffect, useState} from "react";
import {createNewLinkBox, updateExistingLink} from "../../actions/linksActions";
import {useDispatch, useSelector} from "react-redux";
import {AppStateType} from "../../reducers";

import "./errorScss.scss";
import "./createForm.scss";
import ErrorPopover from "../errorPopover";
import {AiOutlineEdit, IoIosCopy} from "react-icons/all";
import Container from "react-bootstrap/Container";
import {useNavigate} from "react-router-dom";
import {getCookieConsentValue} from "react-cookie-consent";
import CookieModal from "../cookieModal";
import asyncLocalStorage from "../../hooks/asyncLocalStorage";

type LinkType = {
    link: string | null;
    description: string | null;
}

const LinkForm = (props: any) => {
    const [links, setLinks] = useState<Array<LinkType>>([{
        link: "", description: ""
    }]);
    const {isFetching, errors, createdBoxLink } = useSelector<AppStateType, any>((state: AppStateType) => state.links);
    const dispatch = useDispatch();
    const [dateRange, setDateRange] = useState(null);
    const [description, setDescription] = useState("");
    const [linkName, setLinkName] = useState("");
    const [isPrivate, setIsPrivate] = useState(false);
    const [password, setPassword] = useState("");
    const [validated, setValidated] = useState(false);
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [processedForm, setProcessedForm] = useState(null);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const cookieValue = getCookieConsentValue();
        if (!cookieValue
            && localStorage.getItem('cookies-ignored') === null
            && localStorage.getItem('uuid') === null) {
            setProcessedForm(e);
            setShowModal(true);
            return;
        }
        handleAcceptedForm(e);
    }

    const handleAcceptedForm = (e: React.BaseSyntheticEvent<HTMLFormElement>) =>{
        const form = e.target;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            setValidated(true)
            return
        }
        setValidated(true)
        const payload = {
            name: linkName,
            images: null,
            description: description,
            fromDate: dateRange,
            links: links,
            isPrivate: isPrivate,
            password: password
        }
        if (props.currentLink !== undefined &&  props.currentLink.id ){
            dispatch(updateExistingLink(payload, props.currentLink.shortLink));
            props.callback();
        }else{
            dispatch(createNewLinkBox(payload));
        }
    }

    const hideModal = () => {
        setShowModal(false)
    }

    useEffect( () => {
        if (!isFetching && createdBoxLink.id !== null) {
            navigate('/created-box-link')
        }}
    ,[navigate, createdBoxLink, isFetching])

    useEffect(() => {
        if (props.currentLink){
            setDescription(props.currentLink.description);
            setLinkName(props.currentLink.name);
            setIsPrivate(props.currentLink.isPrivate);
            setLinks(props.currentLink.links)
        }
    }, [setDescription, props])

    const addLink = () => {
        const emptyLink: LinkType = {description: "", link: ""};
        setLinks(links => [...links, emptyLink]);
    }
    const deleteLink = (linkIndex: number) => {
        const newLinks = links
            .filter((_, index) => index !== linkIndex);
        setLinks(newLinks);
    }
    const updateLink = (index: number, link: string, description: string) => {
        let linkElements = [...links];
        linkElements[index] = {
            ...linkElements[index],
            link: link,
            description: description
        };
        setLinks(linkElements);
    }
    const copyToClipboard = (value: any) => {
        navigator.clipboard.writeText(value);
    }
    return (
        <div className={`w-100 m-auto ${props.currentLink ? "" : "pt-5"} form-section pb-5 text-center`}>
            <CookieModal
                show={showModal}
                closeCallback={hideModal}
                acceptCallback={handleAcceptedForm}
                form={processedForm}
            />
            <Container id="create-section" fluid={"sm"}>
                {props.currentLink ? null :
                    <div>
                        <h3 className={"mb-4"}>Create your new Boxlink</h3>
                    </div>
                }
            <Form onSubmit={handleSubmit} noValidate validated={validated}>
            <Form.Group className={"mt-3 mb-3 "}>
                <Form.Control id="linkName"
                              name="linkName"
                              type="text"
                              required
                              size={"lg"}
                              placeholder="Your project name"
                              value={linkName}
                              style={{fontWeight: 700}}
                              onChange={(e) => setLinkName(e.target.value)}
                />
            </Form.Group>
            <Form.Group className={"mt-3 mb-3"}>
                <Form.Control id="generalDescription"
                              name="generalDescription"
                              as="textarea"
                              rows={3}
                              placeholder="Your project description"
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                />
            </Form.Group>
            <Form.Group className={"mt-3 mb-3 d-flex flex-row"}>
                <div className={"copy-link-tooltip"}>
                    <Form.Check
                        id={`isPrivate${props.id}`}
                        name={`isPrivate${props.id}`}
                        checked={isPrivate}
                        type={"checkbox"}

                        onChange={(e)=> setIsPrivate(!isPrivate)}
                        label={"Is private"}
                        disabled={props.currentLink !== undefined && props.currentLink.id}
                        style={{marginTop: "6px", minHeight: "2rem", minWidth: "100px"}}
                    />
                    {
                        props.currentLink !== undefined && props.currentLink.id ?
                            <span className={"link-tooltip"}
                                  style={{fontSize: "0.9rem"}}>Cannot change privacy of saved Boxlink</span>
                            : null
                    }
                </div>



                <Form.Group controlId="validationCustom03"
                            className={
                                props.currentLink!== undefined && props.currentLink.id ? "ms-3 d-none" : isPrivate ? "w-100 ms-3" : "ms-3 d-none"
                            }
                >
                    <Form.Control
                        // id="password"
                        name="password"
                        type="text"
                        placeholder="Access password."
                        required={isPrivate}
                        value={password}
                        disabled={props.currentLink !== undefined && props.currentLink.id}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                        Please provide a password.
                    </Form.Control.Feedback>

                </Form.Group>
                <label
                    className={props.currentLink !== undefined && props.currentLink.id ? "d-none": isPrivate ? "copy-button" : "d-none"}
                    onClick={() => copyToClipboard(password)}>
                    copy
                    {/*<IoIosCopy/>*/}
                </label>

            </Form.Group>
            <Form.Group className={"mt-3"}>

            </Form.Group>
            {links.map((link: any, index) => {
                return <LinkElement
                    link={link}
                    index={index}
                    key={index}
                    deleteLink={deleteLink}
                    updateLink={updateLink}
                />
            })}
            {links.length < 4 ? <Button
                className={"m-2"}
                variant={"secondary"}
                onClick={addLink}
            >Add a link</Button> : null }
            {links.length > 0 ? <Button
                variant={"primary"}
                type="submit"
                className={"m-0"}
            >Save Boxlink</Button> : null}
            {props.currentLink ?
                <Button
                    variant={"danger"}
                    type={"reset"}
                    className={"m-2"}
                    onClick={() => {props.callback()}}
                >Cancel</Button>
            : null}

        </Form>
            <div className={"d-flex flex-column error-container"}>

                {errors ? Object.keys(errors).map((key: any) => {
                    return <ErrorPopover error={errors[key]} index={key}/>}
                    )
                : null}
            </div>
            </Container>
        </div>
    )
}
export default LinkForm;