import axios from "axios";
import {goto404} from "../actions/linksActions";


export const setupResponseInterceptor = (store, history) =>
    axios.interceptors.response.use(response => {
        return response;
    }, (error) => {
        if ([401, 403].includes(error.response.status) || [401, 403].includes(error.response.statusCode)){

        }
        if ([404].includes(error.response.status)){
            // store.dispatch(goto404());
            // window.location.href = '/404';
        }
        return Promise.reject(error);
    })

const get = (path, params, additionalHeaders= {}, response_type = 'application/json') => {
    let headers = {
        "Content-Type": response_type,
        "X-Request-UUID": localStorage.getItem('uuid'),
        ...additionalHeaders
    }
    const uuid = localStorage.getItem('uuid');
    if (uuid !== null) {
        headers["X-Request-UUID"] = uuid;
    }
    return axios({
        headers: headers,
        method: "get",
        url: path,
        params,
        responseType: response_type

    });
}
const put = (path, body) => {
    let headers = {
        "Content-Type": 'application/json',
        "X-Request-UUID": localStorage.getItem('uuid'),
    }
    const uuid = localStorage.getItem('uuid');
    if (uuid !== null) {
        headers["X-Request-UUID"] = uuid;
    }
    return axios({
        headers: headers,
        method: "put",
        url: path,
        data: body ? JSON.stringify(body) : undefined,
    });
};
const post = (path, body) => {
    let headers = {
        "Content-Type": 'application/json',
        "X-Request-UUID": localStorage.getItem('uuid'),
    }
    const uuid = localStorage.getItem('uuid');
    if (uuid !== null) {
        headers["X-Request-UUID"] = uuid;
    }
    return axios({
        headers: headers,
        credentials: "same-origin",
        method: "post",
        url: path,
        data: JSON.stringify(body) ? body : undefined,
    });

};
const remove = (path, body) => {
    let headers = {
        "Content-Type": 'application/json',
        "X-Request-UUID": localStorage.getItem('uuid'),
    }
    const uuid = localStorage.getItem('uuid');
    if (uuid !== null) {
        headers["X-Request-UUID"] = uuid;
    }
    return axios({
        headers: headers,
        credentials: "same-origin",
        method: "delete",
        url: path,
        data: JSON.stringify(body) ? body : undefined,
    });
}
const myAxios = {
    GET: get,
    POST: post,
    PUT: put,
    DELETE: remove,
    DEFAULTS: axios.defaults
};
export default myAxios;
