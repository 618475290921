import {AuthActionTypes} from "../actions/authActions";
import {
    LOGIN_TG_USER_REQUEST_TYPE,
    LOGIN_TG_USER_REQUEST_TYPE_ERROR,
    LOGIN_TG_USER_REQUEST_TYPE_SUCCESS
} from "../constants/actionTypes";

export type AuthInitialStateType = {
    isFetching: boolean;
    errors: any;
    tg_user: any;
    uuid: any;
}

const authInitialState = {
    isFetching: false,
    error: null,
    tg_user: null,
    uuid: null
}

export const auth = (
    state = authInitialState, action: AuthActionTypes
) => {
    switch(action.type){
        case LOGIN_TG_USER_REQUEST_TYPE:
            return {
                ...state,
                isFetching: true
            }
        case LOGIN_TG_USER_REQUEST_TYPE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                uuid: action.uuid,
                tg_user: action.tg_user
            }
        case LOGIN_TG_USER_REQUEST_TYPE_ERROR:
            return {
                ...state,
                isFetching: false,
                errors: action.error
            }
        default:
            return state
    }
}