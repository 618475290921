import {BiUser} from "react-icons/all";
import React, {useState, useEffect} from "react";
import "./loggedInUser.scss";
import {Nav, Navbar, NavDropdown} from "react-bootstrap";
import {isMobile} from "react-device-detect";

interface Props {
    logout: any
}

const LoggedInUser: React.FC<Props> = ({logout}) => {

    const [tgUser, setTgUser] = useState<any>(null);

    useEffect(() => {
        const tg_stored = localStorage.getItem('tgUser');
        if (tg_stored){
            const tg_user =  JSON.parse(tg_stored);
            setTgUser(tg_user)
        }
    }, [])

    return (
        <div
            className={`d-flex`}
        >
            <Navbar.Collapse id="user-dropdown"
                             className={`${isMobile ? 'd-none' : ''}`}
            >
                <Nav>
                    <NavDropdown
                        // id="nav-dropdown"
                        title={
                            <BiUser
                                className={"cursor-hover"}
                                style={{fontSize: "1.5rem", color: "white"}}
                            />
                        }
                        // menuVariant="dark"
                        align={'end'}
                    >
                        <div className={"dropdown-item"}>
                            {tgUser?.first_name ? tgUser.first_name : tgUser?.username ? tgUser.username : <></> }
                        </div>
                        <NavDropdown.Item href="#" onClick={() => logout()}>Logout</NavDropdown.Item>
                    </NavDropdown>
                </Nav>
            </Navbar.Collapse>

            {/*<div*/}
            {/*    className={"d-flex drop-menu"}*/}
            {/*>*/}
            {/*    <div>*/}
            {/*        {tgUser?.first_name ? tgUser.first_name : tgUser?.username ? tgUser.username : null }*/}
            {/*    </div>*/}
            {/*    <div>*/}
            {/*        <a*/}
            {/*            onClick={logout}*/}
            {/*            className={"logout"}>*/}
            {/*            Logout*/}
            {/*        </a>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )
}
export default LoggedInUser;
