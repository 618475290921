
import "./smallBoxLinkCard.scss";
import {AiOutlineCheckCircle, IoIosCopy} from "react-icons/all";
import React, {useEffect, useState} from "react";
import {sendLinkEvent} from "../../actions/linksActions";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";



const SmallBoxLinkCard = (props: any) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [copied, setCopied] = useState(false);

    const copyToClipboard = (value: any) => {
        try{
            navigator.clipboard.writeText(`https://boxlink.one/l/${value}`);
        } catch (e) {
            const elem = document.createElement('textarea');
            elem.value = value;
            document.body.appendChild(elem);
            elem.select();
            document.execCommand('copy');
            document.body.removeChild(elem);
        }
        setCopied(true)
        handleShareEvent(null, value, 'clipboard-copy');
        setTimeout(() => setCopied(false), 1500);
    }
    const handleShareEvent = (e: any, value: string, event_type: string) => {
        dispatch(sendLinkEvent(value, event_type))
    }
    const date = props.link.validUntil ? new Date(Date.parse(props.link.validUntil)).toDateString() : null;

    return (
        <div className={"p-4 small-card "}>
            <h4
                onClick={async event => {await navigate(`/l/${props.link.shortLink}`)}}
            >
                    {props.link.name}
            </h4>
            <div
                className={"link-description"}
            >
                {`${props.link?.description?.substring(0,100)}...`}
            </div>
            <div>
            <div className={"align-items-right p-2"}
                style={{fontSize:  "15px", display: "flex", justifyContent: "space-between"}}
            >
                    <span className={"copy-link-tooltip"}>
                        <IoIosCopy
                            className={"copy-link-icon "}
                            onClick={() => copyToClipboard(props.link.shortLink)}>
                        </IoIosCopy>
                        <AiOutlineCheckCircle
                            className={"copy-acknowledged fade-out-forwards"}
                            id={`${props.link.shortLink}-copied`}
                            visibility={copied ? "visible" : "hidden"}
                        />
                    <span className={"link-tooltip"}>Copy boxlink to clipboard</span>
                    Links: {props.link.linksCount}
                </span>
                <span>Views: {props.link.viewsCount}</span>
            </div>
            <div>{!!date ? <span className={"valid-span"}>Valid until: {date}</span>: null}</div>
            </div>
        </div>
    )
}
export default SmallBoxLinkCard;