
import myAxios from "../utils/axios";
import {endpoint} from "../constants/constants";



async function createLinkBox(body: any) {
    const url = `${endpoint}/api/links`;
    const response = await myAxios.POST(
        url,
        body
    );
    return response.data;
}

async function getLink(linkId: string,  token: string | null) {
    const url = `${endpoint}/api/l/${linkId}`;
    let headers = {};
    if (token) {
        headers = {
            "Access-Token": token
        }
    }
    try {
        const response = await myAxios.GET(
            url, null, headers
        )
        return response.data;
    } catch (e:any) {

        throw e
    }
}

async function unlockBoxLink(payload: any) {
    const url = `${endpoint}/api/unlock-link/`
    try {
        const  response = await myAxios.POST(
            url, payload
        )
        return response.data;
    } catch (e: any) {

        throw e;
    }
}

async function getPublicLinks(offset: number = 0, limit?: number) {
    const params:any = {
        offset: offset
    }
    if (limit) {
        params.limit = limit
    }
    const url = `${endpoint}/api/links`
    try {
        const  response = await myAxios.GET(
            url, params
        )
        return response.data;
    } catch (e: any) {

        throw e;
    }
}
async function getMyLinks(offset: number = 0, limit: number = 9) {
    let params: any = {
        offset: offset
    }
    if (limit) {
        params.limit = limit
    }
    const url = `${endpoint}/api/my-links`
    try {
        const  response = await myAxios.GET(
            url, params
        )
        return response.data;
    } catch (e: any) {

        throw e;
    }
}

async function sharedLinkEvent(link: string, eventType: string) {
    const url = `${endpoint}/api/event/`;
    const payload = {
        event: 'shared',
        link: link,
        type: eventType
    }
    try {
        await myAxios.POST(
            url, payload
        )
    }catch (e: any) {

    }
}
async function updateBoxLink(payload: any, link: string){
    const url = `${endpoint}/api/l/${link}`;
    const response = await myAxios.PUT(
        url,
        payload
    );
    return response.data;
}
async function deleteBoxLink(link: string) {
    const url = `${endpoint}/api/l/${link}`;
    const response = await myAxios.DELETE(
        url
    )
    return response.data;
}
export {
    createLinkBox,
    getLink,
    unlockBoxLink,
    getPublicLinks,
    sharedLinkEvent,
    updateBoxLink,
    deleteBoxLink,
    getMyLinks
}