import {Button, Form} from "react-bootstrap";
import React, {useState} from "react";
import {FiDelete, RiDeleteBin6Line} from "react-icons/all";
import "./linkFormElement.scss";


const LinkElement = (props: any) => {

    return (
        <div className={"mt-3 mb3 text-left"}>
            <h5>Link #{props.index + 1}</h5>
            <Form.Group className={"d-flex link-form-group "}>
                {/*<Form.Label htmlFor="username">Username</Form.Label>*/}
                <Form.Control id="link"
                              className={"mb-1 me-1"}
                              name="link"
                              type="text"
                              required
                              placeholder="Paste your link"
                              value={props.link.link}
                              onChange={(e) => props.updateLink(props.index, e.target.value, props.link.description)}
                />
                <Form.Control id="description"
                              className={"mb-1 me-1"}
                              name="description"
                              type="text"
                              placeholder="Short link description"
                              value={props.link.description}
                              onChange={(e) => props.updateLink(props.index, props.link.link, e.target.value)}
                />

                <Button
                    variant={""}
                    className={"mb-1"}
                    onClick={() => {props.deleteLink(props.index)}}
                ><RiDeleteBin6Line /></Button>
            </Form.Group>
        </div>
    )
}

export default LinkElement;