import Container from "react-bootstrap/Container";
import React from "react";
import "./topLanding.scss";
import Button from "react-bootstrap/Button";
import LinkForm from "../linkForm";


const TopLandingComponent = () => {

    function scrollTo() {
        let element = document.getElementById("create-section");
        element?.scrollIntoView({block: "center", behavior: "smooth"});
    }
    return (
            <section
                className={"px-lg-5 text-left d-flex container top-section" }
            >
                <div className={" pt-5 text-left mt-3 me-5"}
                    style={{marginTop: "10px;"}}
                >
                    <h1 >Share links with Boxlink</h1>
                    <div className={"top-section-text"}>Combine content across the Web and share it with your audience. Box you links.</div>
                </div>
                <LinkForm id={1} />

            </section>

    )
}
export default TopLandingComponent;