import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import {createStore, applyMiddleware} from "redux";
import mainReducer from "./reducers";
import thunkMiddleware from "redux-thunk";
import {setupResponseInterceptor} from "./utils/axios";
import {composeWithDevTools} from "redux-devtools-extension";

const enhancer = composeWithDevTools(
    applyMiddleware(thunkMiddleware)
);
const store = createStore(mainReducer, enhancer);
setupResponseInterceptor(store);


ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
